export const FEATURES = {
  OPS_FOOTER_GOOGLE_REVIEWS: 'ops_footer_google_reviews',
  REL_ACH_BALANCE_REVIEW_V2: 'rel_ach_balance_review_v2',
  REL_PAYMENT_REQUEST_SUCCESS_V2: 'rel_payment_request_success_v2',
  REL_STRIPE_CHARGE_V2: 'rel_stripe_charge_v2',
  RELEASE_BUYER_APPLICATION_AUTH_REMOVAL:
    'release_buyer_application_auth_removal',
  REL_UPDATED_LITTER_FLOW: 'rel_updated_litter_flow',
  REL_BUYER_APPLICATION_MARKETING_BANNER:
    'rel_buyer_application_marketing_banner',
  REL_BUYER_APPLICATION_BREEDER_PROFILE:
    'rel_buyer_application_breeder_profile',
  REL_BREEDER_REFERRAL_LINKS: 'rel_breeder_referral_links',
  REL_LITTER_PRICING_WITH_PAYOUTS: 'rel_litter_pricing_with_payouts'
};
